import * as React from "react";

import Layout from "../components/layout";
import { RandomPhoto } from "../components/photo";
import Seo from "../components/seo";

const Biography = () => (
  <Layout>
    <Seo title="biography" />
    <tr className="bkg_02">
      <td className="cell-format">
        <RandomPhoto />
      </td>
      <td className="cell-format" valign="top">
        <div className="code">
          Pronunciation: (")dI-'kO-tik
          <br />
          Function: adjective
          <br />
          Etymology: dich- + 2-otic
          <br />
          Date: circa 1911
          <br />: affecting or relating to the two ears differently in regard to
          a conscious aspect (as pitch or loudness) or a physical aspect (as
          frequency or energy) of sound
        </div>
      </td>
    </tr>
    <tr className="bkg_02">
      <td colSpan={2} className="cell-format">
        Dichotic played music you never heard. You don't want to{" "}
        <a href="discography.php">hear it</a> because it will probably go
        soaring right over your <a href="/images/wirehead.jpg">head</a>.<br />
        <br />
        If you listen to <a href="discography.php">the music</a>, you'll
        probably hate it. That's OK though, because you can always dismiss it as
        '
        <a href="http://www.modestypanel.com/artorcrap/" target="crap">
          crap
        </a>
        ' or '<a href="media/huggins.wav">noise</a>'. Which, of course,{" "}
        <a href="images/art02.jpg">it is</a>. But we tried hard.
        <br />
      </td>
    </tr>
  </Layout>
);

export default Biography;

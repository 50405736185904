import * as React from "react";

const iPhotoMin = 1;
const iPhotoMax = 20;

function range(min, max) {
  if (min > max) {
    throw new Error("max cannot be less than min");
  }
  const acc = [];
  for (let i = min; i < max; i++) {
    acc.push(i);
  }

  return acc;
}

const photoIds = range(iPhotoMin, iPhotoMax);

function zeroPad(n, places) {
  return String(n).padStart(places, "0");
}

const Photo = (props) => (
  <div className="photo">
    <img src={`/images/photos/band_${zeroPad(props.id, 2)}.jpg`} />
  </div>
);

const AllPhotos = () => (
  <>
    {photoIds.map((i) => (
      <Photo id={i} key={i} />
    ))}
  </>
);

function getRandomPhotoId() {
  return photoIds[Math.floor(Math.random() * photoIds.length)];
}

const RandomPhoto = () => <Photo id={getRandomPhotoId()} />;

export default Photo;

export { AllPhotos, RandomPhoto };
